/* istanbul ignore file */

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './i18n/i18n';
import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENV === 'stg' || process.env.REACT_APP_ENV === 'uat') {
  Sentry.init({
    dsn: 'https://c4f51fd5385fa196644cf413dbc240d3@o4506613685354496.ingest.sentry.io/4506613999861760',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // tracePropagationTargets: [
    //   'localhost',
    //   /^https:\/\/dev\.corenet\.gov\.sg\/services/,
    //   /^https:\/\/stg\.corenet\.gov\.sg\/services/,
    //   /^https:\/\/uat\.corenet\.gov\.sg\/services/,
    // ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
