import React from 'react';
import { Exclamation, InlineNotification } from '../ui';

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackUI?: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // logErrorToServer(error, errorInfo);
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error } = this.state;
    const { children, fallbackUI } = this.props;

    if (error) {
      // Customize fallback UI or show a default error message
      return (
        fallbackUI || (
          <InlineNotification
            message={'We are unable to complete your request. Please proceed to contact helpdesk for assistance.'}
            icon={<Exclamation />}
            variant="error"
          />
        )
      );
    }

    return children;
  }
}

export default ErrorBoundary;
