/* istanbul ignore file */
import { AxiosError, AxiosPromise } from 'axios';
import { axiosInstance } from './axiosInstance';
import {
  setAccount,
  setIsConcurrentSessionDetected,
  setIsSessionEnding,
  setIsSessionExpired,
} from 'store/auth/actions';
import { clearPaymentReceipts } from 'store/payments/actions';
import { clearStore } from 'store/crossSliceActions';

import type { Dispatch } from 'redux';
import type { IAccount } from 'utils/constants';

export const ping = async (dispatch: Dispatch, extendSession = false): Promise<void> => {
  dispatch(setIsSessionEnding(!extendSession));
  const headers = extendSession ? undefined : { 'cx-skip-refresh': 'true' };
  try {
    await axiosInstance.get(`/account/ping`, { headers });
    dispatch(setIsSessionExpired(false));
    dispatch(setIsConcurrentSessionDetected(false));
  } catch (err) {
    const errData = (err as AxiosError).response?.data as { errorCode?: string };
    switch (errData?.errorCode) {
      case 'NOT_LOGGED_IN':
        dispatch(setIsSessionExpired(true));
        break;

      case 'CONCURRENT_SESSION_DETECTED':
        dispatch(setIsConcurrentSessionDetected(true));
        break;
    }
    window.sessionStorage.clear();
  }
};

interface ILoginUrlResponse {
  url: string;
}

export const getLoginUrl = (provider: 'singpass' | 'corppass'): AxiosPromise<ILoginUrlResponse> =>
  axiosInstance.get<ILoginUrlResponse>(`/auth/url/${provider}`).catch();

interface ILoginResponse {
  account: IAccount;
}

export const login = (
  provider: 'singpass' | 'corppass',
  data: { code: string | null; state: string | null },
): AxiosPromise<ILoginResponse> => axiosInstance.post<ILoginResponse>(`/auth/login/${provider}`, data).catch();

interface IAccountResponse {
  account: IAccount;
}

export const fetchUser = (dispatch: Dispatch): Promise<void> =>
  axiosInstance.get<IAccountResponse>('/account').then(({ data: { account } }) => {
    dispatch(setAccount(account));
    dispatch(setIsSessionExpired(false));
  });

export const logout = (dispatch: Dispatch, navigate: (path: string) => void): Promise<void> =>
  axiosInstance
    .post('/auth/logout')
    .then(() => {
      dispatch(clearPaymentReceipts());
      dispatch(clearStore());
      window.sessionStorage.clear();
      navigate('/');
    })
    .catch();

export const checkBetaAccess = (betaAccessCode: string): Promise<boolean> =>
  axiosInstance
    .post<{ hasBetaAccess: boolean }>('/auth/check-beta-access', { betaAccessCode })
    .then(({ data: { hasBetaAccess } }) => hasBetaAccess);
