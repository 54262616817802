import React from 'react';

export interface IBaseButton {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  textSize?: 'small' | 'regular';
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export type BaseButtonProps = IBaseButton & React.HTMLAttributes<HTMLButtonElement>;

const textSizes = {
  small: 'button-small',
  regular: 'button-regular',
};

const styles: Record<
  string,
  {
    normal: string;
    loading: string;
  }
> = {
  primary: {
    normal: `bg-primary-60 hover:bg-primary-50 active:bg-primary-70 text-white`,
    loading: 'bg-primary-70',
  },
  secondary: {
    normal: `bg-primary-10 hover:bg-primary-20 active:bg-primary-20 text-primary-60 hover:text-primary-70 active:text-primary-70`,
    loading: 'bg-primary-20',
  },
  tertiary: {
    normal: `bg-gray-10 hover:bg-gray-20 active:bg-gray-20 text-gray-80`,
    loading: 'bg-gray-10',
  },
  danger: {
    normal: `bg-error hover:bg-error-dark active:bg-error-dark text-white`,
    loading: 'bg-error-dark',
  },
};

export const BaseButton: React.FC<BaseButtonProps> = ({
  variant = 'primary',
  disabled = false,
  textSize = 'regular',
  isLoading,
  className,
  children,
  ...otherProps
}) => (
  <div className="p-1 w-full">
    <button
      disabled={disabled}
      className={`w-full text-center rounded-lg disabled:cursor-not-allowed disabled:bg-gray-20 disabled:text-white focus:outline-none ring-offset-2 focus:ring-2 focus:ring-primary-60 ${
        styles[variant][isLoading ? 'loading' : 'normal']
      } ${textSizes[textSize]} ${className || ''}`}
      {...otherProps}
    >
      {children}
    </button>
  </div>
);
