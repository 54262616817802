import { QueryClient, MutationCache } from '@tanstack/react-query';
import { isObject } from 'lodash-es';
import { FetchError, shouldRetry } from './fetch';
import { addToast } from 'ui';
import { ERROR_CODE_MAPPING } from '../pages/ErrorBoundary';

import type { QueryKey } from '@tanstack/react-query';
import type { IAddToast } from 'ui/components/Notification';

type ToastFn = (result: unknown, vars: unknown) => IAddToast;

declare module '@tanstack/react-query' {
  interface MutationMeta {
    invalidates?: QueryKey[];
    okToast?: IAddToast | ToastFn;
    errorToast?: IAddToast | ToastFn;
  }
}

/* istanbul ignore next */
const defaultRetry = (count: number, err: unknown) => count < 3 && shouldRetry(err);

const mutationCache = new MutationCache({
  onSuccess: (data, vars, _ctx, mutation) => {
    if (mutation.meta?.okToast) {
      const toast = mutation.meta.okToast;
      addToast({ variant: 'success', ...(typeof toast === 'function' ? toast(data, vars) : toast) });
    }
    if (mutation.meta?.invalidates) {
      mutation.meta.invalidates.map((k) => queryClient.invalidateQueries({ queryKey: k }));
    }
  },
  onError: (err, vars, _ctx, mutation) => {
    if (mutation.meta?.errorToast) {
      const toast = mutation.meta.errorToast;
      addToast({ variant: 'error', ...(typeof toast === 'function' ? toast(err, vars) : toast) });
    }
  },
});

/* istanbul ignore next */
export const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000, // 5s
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: defaultRetry,
      useErrorBoundary: (error) => {
        const errCodeHandledByErrorBoundary = Object.keys(ERROR_CODE_MAPPING);

        if (!isObject(error) || !(error instanceof FetchError)) {
          return false;
        }

        const errorCode = (error as FetchError).errorData.errorCode;
        if (typeof errorCode !== 'string') return false;

        return errCodeHandledByErrorBoundary.includes(errorCode);
      },
    },
    mutations: {
      //
    },
  },
});
