import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { IModal, IModalDismissable } from './interfaces';

export const ModalContent: React.FC<IModal & IModalDismissable> = ({
  header,
  description,
  illustration,
  ctaSection,
  additionalContent,
  buttons,
  withCloseButton,
  closeButtonOnClick,
  spaceBetween,
}) => (
  <div className="relative p-8">
    {withCloseButton && (
      <button
        className="focus:outline-none text-gray-70 focus:text-gray-50 absolute top-4 right-4"
        onClick={closeButtonOnClick}
      >
        <XIcon className="flex-shrink-0 w-5 h-5" />
      </button>
    )}

    <div className={`flex flex-col ${spaceBetween ? `space-y-${spaceBetween}` : 'space-y-8'}`}>
      {(header || illustration || description) && (
        <div className="flex flex-col space-y-2">
          {header && <div className="heading-5-semibold text-gray-80 pr-4">{header}</div>}
          {illustration && <div className="flex justify-center w-full py-2">{illustration}</div>}
          {description && <div className="body-small text-gray-70 whitespace-pre-wrap">{description}</div>}
          {additionalContent && <div className="body-small text-gray-70">{additionalContent}</div>}
        </div>
      )}

      {ctaSection}

      {buttons && <div className="flex space-x-2 self-end">{buttons}</div>}
    </div>
  </div>
);
