import dayjs, { ManipulateType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const FULL_DATE_SHORT_MONTH = 'D MMM YYYY'; // Used only in table
const FULL_DATE_LONG_MONTH = 'D MMMM YYYY'; // Default should use this format

const CALENDER_LONG_MONTH = 'MMMM YYYY'; // Only used for calender, when selecting month
const FULL_DATE_NUMERIC_MONTH = 'DD/MM/YYYY'; // Only used for field level formatting

const EXTERNAL_FULL_DATE = 'YYYY-MM-DD'; // Only used when we sending to external system such as manifest or site inspection
const TIME = 'h:mma';
const FULL_DATE_LONG_MONTH_WITH_TIME = 'h:mma, D MMMM YYYY';
const FULL_DATE_SHORT_MONTH_WITH_TIME = 'h:mma, D MMM YYYY';
const SITE_INSPECTION_DATE_TIME = 'YYYY-MM-DDTHH:mm';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const formatDateWithLongMonth = (date: Date) => formatDate(date, FULL_DATE_LONG_MONTH);
export const formatDateWithShortMonth = (date: Date) => formatDate(date, FULL_DATE_SHORT_MONTH);
export const formatLongMonthWithYear = (date: Date) => formatDate(date, CALENDER_LONG_MONTH);
export const formatDateInNumeric = (date: Date) => formatDate(date, FULL_DATE_NUMERIC_MONTH);
export const formatTime = (date: Date) => formatDate(date, TIME);
export const formatDateForExternalUse = (date: Date) => formatDate(date, EXTERNAL_FULL_DATE);
export const formatDateTimeWithLongMonth = (date: Date) => formatDate(date, FULL_DATE_LONG_MONTH_WITH_TIME);
export const formatDateTimeWithShortMonth = (date: Date) => formatDate(date, FULL_DATE_SHORT_MONTH_WITH_TIME);
export const formatDateTimeForSiteInspection = (date: Date) => formatDate(date, SITE_INSPECTION_DATE_TIME);

export const formatDate = (date: Date, format = FULL_DATE_SHORT_MONTH): string => dayjs(date).format(format);

export const parseDateFromString = (dateString: string, format = DEFAULT_DATE_FORMAT): Date =>
  dayjs(dateString, format).toDate();

/**
 * @returns Returns either how long ago the date is from now eg. `15 minutes ago` or the date itself
 * (if it is more than 1 month ago) eg. `11 Jul 2023`.
 */
export const getReadableDateString = (date: Date): string => {
  return dayjs(date).add(1, 'M').isBefore(dayjs()) ? formatDate(date) : dayjs(date).fromNow();
};

export const addTime = (from: Date, amount: number, units: ManipulateType): Date => {
  return dayjs(from).add(amount, units).toDate();
};

export const isSameDayOrAfter = (date: Date, reference: Date): boolean => {
  return dayjs(date).isSameOrAfter(dayjs(reference), 'day');
};

export const isSameDayOrBefore = (date: Date, reference: Date): boolean => {
  return dayjs(date).isSameOrBefore(dayjs(reference), 'day');
};

export const isSameDayTimeOrBefore = (date: Date, reference: Date): boolean => {
  return dayjs(date).isSameOrBefore(dayjs(reference), 'millisecond');
};

/**
 * `isBefore(a, b)` means `a` is earlier than `b`
 */
export const isBefore = (date: Date, reference: Date) => dayjs(date).isBefore(dayjs(reference));

export const areSameDays = (date1: Date | string, date2: Date | string): boolean => {
  return dayjs(date1).isSame(dayjs(date2), 'day');
};

export const getTimeDifferenceInSeconds = (date1: Date, date2: Date): number => {
  return Math.abs(dayjs(date1).diff(dayjs(date2), 'second'));
};
