import { ActionCreator } from 'redux';
import { IPayment, IPaymentTransaction, IGuestPayment } from 'utils/constants';

export const SET_MY_PAYMENTS = 'SET_MY_PAYMENTS';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const CLEAR_PAYMENTS_FOR_SUBMISSION = 'CLEAR_PAYMENTS_FOR_SUBMISSION';
export const SET_PAYMENT_TRANSACTION = 'SET_PAYMENT_TRANSACTION';
export const SET_EMAIL_ADDRESSES = 'SET_EMAIL_ADDRESSES';
export const SET_SELECTED_PAYMENT_IDS = 'SET_SELECTED_PAYMENT_IDS';
export const ADD_SELECTED_PAYMENT_ID = 'ADD_SELECTED_PAYMENT_ID';
export const REMOVE_SELECTED_PAYMENT_ID = 'REMOVE_SELECTED_PAYMENT_ID';
export const SET_PAYMENT_RECEIPT = 'SET_PAYMENT_RECEIPT';
export const CLEAR_PAYMENT_RECEIPTS = 'CLEAR_PAYMENT_RECEIPTS';
export const SET_GUEST_PAYMENT = 'SET_GUEST_PAYMENT';
export const SET_INVALID_PAYMENT = 'SET_INVALID_PAYMENT';

interface ISetMyPayments {
  type: typeof SET_MY_PAYMENTS;
  payload: { payments: IPayment[] };
}

export const setMyPayments: ActionCreator<ISetMyPayments> = (payments: IPayment[]) => ({
  type: SET_MY_PAYMENTS,
  payload: { payments },
});

interface ISetPayment {
  type: typeof SET_PAYMENT;
  payload: { payment: IPayment };
}

export const setPayment: ActionCreator<ISetPayment> = (payment: IPayment) => ({
  type: SET_PAYMENT,
  payload: { payment },
});

interface ISetPayments {
  type: typeof SET_PAYMENTS;
  payload: { payments: IPayment[] };
}

export const setPayments: ActionCreator<ISetPayments> = (payments: IPayment[]) => ({
  type: SET_PAYMENTS,
  payload: { payments },
});

interface IClearPaymentsForSubmission {
  type: typeof CLEAR_PAYMENTS_FOR_SUBMISSION;
  payload: { submissionId: string };
}

export const clearPaymentsForSubmission: ActionCreator<IClearPaymentsForSubmission> = (submissionId: string) => ({
  type: CLEAR_PAYMENTS_FOR_SUBMISSION,
  payload: { submissionId },
});

interface ISetPaymentTransaction {
  type: typeof SET_PAYMENT_TRANSACTION;
  payload: { id: string; paymentTransaction: IPaymentTransaction | null };
}

export const setPaymentTransaction: ActionCreator<ISetPaymentTransaction> = (
  id: string,
  paymentTransaction: IPaymentTransaction | null,
) => ({
  type: SET_PAYMENT_TRANSACTION,
  payload: { id, paymentTransaction },
});

interface ISetEmailAddresses {
  type: typeof SET_EMAIL_ADDRESSES;
  payload: { emailAddresses: string[]; transactionId: string };
}

export const setEmailAddresses: ActionCreator<ISetEmailAddresses> = (
  emailAddresses: string[],
  transactionId: string,
) => ({
  type: SET_EMAIL_ADDRESSES,
  payload: { emailAddresses, transactionId },
});

interface ISetSelectedPaymentIds {
  type: typeof SET_SELECTED_PAYMENT_IDS;
  payload: { selectedPaymentIds: string[] };
}

export const setSelectedPaymentIds: ActionCreator<ISetSelectedPaymentIds> = (selectedPaymentIds: string[]) => ({
  type: SET_SELECTED_PAYMENT_IDS,
  payload: { selectedPaymentIds },
});

interface IAddSelectedPaymentId {
  type: typeof ADD_SELECTED_PAYMENT_ID;
  payload: { paymentId: string };
}

export const addSelectedPaymentId: ActionCreator<IAddSelectedPaymentId> = (paymentId: string) => ({
  type: ADD_SELECTED_PAYMENT_ID,
  payload: { paymentId },
});

interface IRemoveSelectedPaymentId {
  type: typeof REMOVE_SELECTED_PAYMENT_ID;
  payload: { paymentId: string };
}

export const removeSelectedPaymentId: ActionCreator<IRemoveSelectedPaymentId> = (paymentId: string) => ({
  type: REMOVE_SELECTED_PAYMENT_ID,
  payload: { paymentId },
});

interface ISetPaymentReceipt {
  type: typeof SET_PAYMENT_RECEIPT;
  payload: { paymentId: string; receiptUrl: string | null };
}

export const setPaymentReceipt: ActionCreator<ISetPaymentReceipt> = (paymentId: string, receiptUrl: string | null) => ({
  type: SET_PAYMENT_RECEIPT,
  payload: { paymentId, receiptUrl },
});

interface IClearPaymentReceipts {
  type: typeof CLEAR_PAYMENT_RECEIPTS;
  payload: Record<string, never>;
}

export const clearPaymentReceipts: ActionCreator<IClearPaymentReceipts> = () => ({
  type: CLEAR_PAYMENT_RECEIPTS,
  payload: {},
});

interface ISetGuestPayment {
  type: typeof SET_GUEST_PAYMENT;
  payload: { guestPayment: IGuestPayment; paymentIds: string[] };
}

export const setGuestPayment: ActionCreator<ISetGuestPayment> = (
  guestPayment: IGuestPayment,
  paymentIds: string[],
) => ({
  type: SET_GUEST_PAYMENT,
  payload: { guestPayment, paymentIds },
});

interface ISetInvalidPayment {
  type: typeof SET_INVALID_PAYMENT;
  payload: { isInvalidPayment: boolean };
}

export const setInvalidPayment: ActionCreator<ISetInvalidPayment> = (isInvalidPayment: boolean) => ({
  type: SET_INVALID_PAYMENT,
  payload: { isInvalidPayment },
});

export type IPaymentActions =
  | ISetMyPayments
  | ISetPayment
  | ISetPayments
  | ISetPaymentTransaction
  | ISetEmailAddresses
  | ISetSelectedPaymentIds
  | IAddSelectedPaymentId
  | IRemoveSelectedPaymentId
  | ISetPaymentReceipt
  | ISetInvalidPayment
  | IClearPaymentReceipts
  | ISetGuestPayment
  | IClearPaymentsForSubmission;
