import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount, setIsSessionExpired, setIsConcurrentSessionDetected } from 'store/auth/actions';
import { getIsLoggedIn } from 'store/auth/selectors';
import { fetchUser, axiosInstance } from 'apis';
import { AxiosError } from 'axios';

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);

  const concurrentSessionDetectionHandler = (error: AxiosError<{ errorCode: string }>) => {
    const { response } = error;
    switch (response?.data.errorCode) {
      case 'CONCURRENT_SESSION_DETECTED':
        dispatch(setAccount(null));
        dispatch(setIsConcurrentSessionDetected(true));
        window.sessionStorage.clear();
        return;
    }

    return Promise.reject(error);
  };

  const errorHandler = (error: AxiosError<{ errorCode: string }>) => {
    const { response } = error;

    switch (response?.data.errorCode) {
      case 'NOT_LOGGED_IN':
        dispatch(setAccount(null));
        dispatch(setIsSessionExpired(true));
        window.sessionStorage.clear();
        return;

      case 'CONCURRENT_SESSION_DETECTED':
        dispatch(setAccount(null));
        dispatch(setIsConcurrentSessionDetected(true));
        window.sessionStorage.clear();
        return;
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    // Only handle concurrent session logout errors on initial page load
    fetchUser(dispatch).catch(concurrentSessionDetectionHandler);
    // Handle all kinds of logout errors after initial page load
    axiosInstance.interceptors.response.use((res) => res, errorHandler);
  }, [isLoggedIn]);

  return isLoggedIn === null ? null : <>{children}</>;
};
