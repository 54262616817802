import { getSubmissionAttachmentDownloadPath } from 'apis';
import { VALID_FILE_UPLOAD_EXTNS } from './constants';
import { IFileInfo, IRawSubmissionAttachment } from './interfaces/IFileInfo';

/* istanbul ignore next */
export const downloadAttachment = async ({
  fileKey,
  submissionId,
  fileName,
}: {
  fileKey: string;
  submissionId: string;
  fileName: string;
}) => {
  const url = await getSubmissionAttachmentDownloadPath({ fileKey, submissionId });
  window.open(url, fileName, 'noreferrer');
};

export const stripEntExtensions = (filename: string): string => filename.replace(/(\.ent)*$/g, '');

export const getFileExtn = (fileName: string, stripEnt?: boolean) => {
  if (stripEnt) fileName = fileName.replaceAll('.ent', '');
  return fileName.slice(fileName.lastIndexOf('.') + 1);
};

export const isDefaultValidFileExt = (ext: string) => VALID_FILE_UPLOAD_EXTNS.includes(ext.toLowerCase());

/** Converts `IRawSubmissionAttachment` from API calls to frontend `IFileInfo` format */
export const processSubmissionAttachment = (attachment: IRawSubmissionAttachment): IFileInfo => {
  const processed = {
    ...attachment,
    fileInfo: undefined,
    name: attachment.fileInfo.name,
    size: attachment.fileInfo.size,
    key: attachment.fileInfo.key,
    uploadedAt: new Date(attachment.fileInfo.uploadedAt),
    receivedStatus: attachment.fileInfo.received,
    lbvModels: attachment.lbvModels?.map((model) => ({
      ...model,
      expiryDate: model.expiryDate === null ? null : new Date(model.expiryDate),
      lbvRequest: {
        requestDate: new Date(model.lbvRequest.requestDate),
      },
    })),
  };

  return processed;
};
