export const defaultFeatureFlags = {
  hasLoaded: false,
  enableSandbox: false,
  enableWorkflowConfig: false,
  enableQuickFilePolling: false,
  enableQuickSubmissionLock: false,
  showFormBuilder: false,
  enableMockAccountDashboard: false,
  enableToggleFeatureFlagPanel: false,
  enableSubmissionHelpers: false,
  restrictAccessToBeta: false,
  enableDspV3: false,
  enableConsolidatedDeclaration: false,
  enableReviewDeclaration: false,
  enableCreateSubmissionV2_DSP: false,
  enableCreateSubmissionV2_35Gateway: false,
  enableCreateSubmissionV2_FullDemolition: false,
  enableCreateSubmissionV2_EWPlanApplication: false,
  enableCreateSubmissionV2_StPlanPermit: false,
  enableCreateSubmissionV2_PermitToCarrySTWorks: false,
  enableBCALodgementStPlanPermit: false,
  enableBCALodgementDifferentSTWORKSQPNotification: false,
  enableReplaceTCAP: false,
  enableTopCscRequirementsTable: false,
  enablePaidEarlier: false,
  enableCreateProjectV2: false,
  enableEditProfileV2: false,
  enableEditDevOrg: false,
  enableDeletionOfNonBimFiles: false,
  enableAddRemoveDeveloperOrg: false,
  enableReplaceDevOrg: false,
  enableSubmissionSearch: false,
  enableFullDemolitionV3: false,
  enableFullDemolitionV3Enhancements: false,
  enableSiteInspection: false,
  enableValidLicenseCheck: false,
  enableRemovePEStructRole: false,
  enableEditAdditionalDetailsJtc: false,
  enableShowAutoAssignedProjectParty: false,
  enableWDBannerEnhancement: false,
  enableSelectSubmissionQp: false,
  enableEditRelatedStructuralPlans: false,
  enableFullDemolitionBannerStWorks: false,
  enableRemovalOfAppointedResponsibilities: false,
  enableFixedInstallationIndependentTechclearance: false,
  enableMCPreCheck: false,
  enableStPlanAndPermitSameQp: false,
  enableSiteInspectionSoftBooking: false,
  enableRemoveAssistant: false,
  enableRequireChangeForPlanAmendment: false,
  enableDevOrgOwner: false,
  enableCscPubAssignProjectParty: false,
  enableNewFormAvailableBanner: false,
  enableFullDemolitionURAClearanceCheck: false,
  enablePermitRevocation: false,
  enableReviewIfWDSectionUnchanged: false,
  enableConcurrentCreationOfDGCGPG: false,
  enableLoginPageV2: false,
  enableDeleteProject: false,
  enableAppointmentsPageV2: false,

  enableSubmissionAopoStatus: false,
  enableCreationWizardV2ForDG: false,
  enableCreationWizardV2ForPG: false,
  enableCreationWizardV2ForCG: false,
  enableSingpassEditCompanyDetails: false,
  enableDigitalSigningTimeout: false,
  enableEnhancePlanAppApplicableSection: false,
  enableEnhanceFullDemolitionNotRequiredSection: false,
};

export type FeatureFlags = typeof defaultFeatureFlags;
