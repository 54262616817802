import { ActionCreator } from 'redux';
import { IAppointmentRequests, ICurrentAccountProjectInfo, IProject } from 'utils/constants';

export const SET_PROJECT = 'SET_PROJECT';

interface ISetProject {
  type: typeof SET_PROJECT;
  payload: { id: string; project: IProject | null; isLoading?: boolean };
}

export const setProject: ActionCreator<ISetProject> = (id: string, project: IProject | null, isLoading?: boolean) => ({
  type: SET_PROJECT,
  payload: { id, project, isLoading },
});

export const SET_PROJECTS = 'SET_PROJECTS';

interface ISetProjects {
  type: typeof SET_PROJECTS;
  payload: { projects: IProject[] | null };
}

export const setProjects: ActionCreator<ISetProjects> = (projects: IProject[]) => ({
  type: SET_PROJECTS,
  payload: { projects },
});

export const SET_OWN_PROJECTS = 'SET_OWN_PROJECTS';

interface ISetOwnProjects {
  type: typeof SET_OWN_PROJECTS;
  payload: { ids: string[] | null };
}

export const setOwnProjects: ActionCreator<ISetOwnProjects> = (ids: string[] | null) => ({
  type: SET_OWN_PROJECTS,
  payload: { ids },
});

export const SET_CURRENT_ACCOUNT_PROJECT_INFO = 'SET_CURRENT_ACCOUNT_PROJECT_INFO';

interface ISetCurrentAccountProjectInfo {
  type: typeof SET_CURRENT_ACCOUNT_PROJECT_INFO;
  payload: { id: string; info: ICurrentAccountProjectInfo | null };
}

export const setCurrentAccountProjectInfo: ActionCreator<ISetCurrentAccountProjectInfo> = (
  id: string,
  info: ICurrentAccountProjectInfo | null,
) => ({
  type: SET_CURRENT_ACCOUNT_PROJECT_INFO,
  payload: { id, info },
});

export const REMOVE_PROJECT_MEMBER = 'REMOVE_PROJECT_MEMBER';

interface IRemoveProjectMember {
  type: typeof REMOVE_PROJECT_MEMBER;
  payload: { projectId: string; projectMemberId: string };
}

export const removeProjectMember: ActionCreator<IRemoveProjectMember> = (
  projectId: string,
  projectMemberId: string,
) => ({
  type: REMOVE_PROJECT_MEMBER,
  payload: { projectId, projectMemberId },
});

export const SET_APPOINTMENTS_INFO = 'SET_APPOINTMENTS_INFO';

interface IsetAppointmentsInfo {
  type: typeof SET_APPOINTMENTS_INFO;
  payload: {
    id: string;
    appointmentRequests: IAppointmentRequests[];
  };
}

export const setAppointmentsInfo: ActionCreator<IsetAppointmentsInfo> = (
  id: string,
  appointmentRequests: IAppointmentRequests[],
) => ({
  type: SET_APPOINTMENTS_INFO,
  payload: { id, appointmentRequests },
});

export type IProjectActions =
  | ISetProject
  | ISetProjects
  | ISetOwnProjects
  | ISetCurrentAccountProjectInfo
  | IRemoveProjectMember
  | IsetAppointmentsInfo;
