import React, { useState, useEffect, useCallback } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import { IDateRange } from './types';
import moment from 'moment';
import { Input } from '../Input/Input';
import classNames from 'classnames';

interface IDatePickerInput {
  value?: string;
  openCalendar: (open: boolean) => void;
  isCalendarOpen: boolean;
  placeholderText?: string;
  setDate: (date: Date | null) => void;
  limit: IDateRange;
  validDates?: Date[];
  label?: string;
  helperText?: string;
  errorMessage?: string;
  valid?: boolean;
  forceErrorShown?: boolean;
}

export const formatDate = (rawInput: string, previousInput?: string) => {
  let input = rawInput.replace(/[^0-9\/]+/g, '');

  //Handle double //, ignore the 2nd one
  if (rawInput === `${previousInput}/` && previousInput && previousInput[previousInput.length - 1] === '/') {
    return previousInput;
  }

  //handle backspace
  if (previousInput === `${rawInput}/`) {
    return input;
  }

  //Add / for user if condition met
  if (/^\d{2}$/.test(rawInput)) {
    input = `${rawInput}/`;
  }
  if (/^\d{2}\/\d{2}$/.test(rawInput)) {
    input = `${rawInput}/`;
  }

  return input;
};

export const DatePickerInput: React.FC<IDatePickerInput> = ({
  value,
  openCalendar,
  isCalendarOpen,
  placeholderText = 'DD/MM/YYYY',
  setDate,
  limit,
  validDates,
  label,
  helperText,
  errorMessage,
  valid,
  forceErrorShown,
}) => {
  const [candidateValue, setCandidateValue] = useState(value);
  const [isDirty, setIsDirty] = useState(false);
  //This error message is only used at component level, error message here will not stop the form from proceeding
  const [componentErrorMessage, setComponentErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    value ? setCandidateValue(value) : setCandidateValue('');
    setComponentErrorMessage(undefined);
  }, [value]);

  const tryUpdateValue = useCallback(() => {
    //When user delete date or when component focus and blurr without typing anything
    if (candidateValue === '' || candidateValue === undefined) {
      setDate(null);
      return;
    }

    const candidateMoment = moment(candidateValue, 'DD/MM/YYYY', true);

    //No change
    if (candidateMoment.isSame(moment(value, 'DD/MM/YYYY'))) {
      return;
    }

    if (
      !(
        candidateMoment.isValid() &&
        candidateMoment.isAfter(moment('1000-1-1', 'yyyy-mm-dd')) &&
        candidateMoment.isBefore(moment('9999-1-1', 'yyyy-mm-dd'))
      )
    ) {
      //Invalid date
      setCandidateValue(undefined);
      setComponentErrorMessage(`${candidateValue} is not valid. Please select a valid date.`);
    } else if (
      !(
        (!limit.startDate || candidateMoment.isAfter(moment(limit.startDate))) &&
        (!limit.endDate || candidateMoment.isBefore(moment(limit.endDate))) &&
        (!validDates ||
          validDates.some(
            (validDate) =>
              candidateMoment.dayOfYear() === moment(validDate).dayOfYear() &&
              candidateMoment.year() === moment(validDate).year(),
          ))
      )
    ) {
      //Date not available, either not in range or not in valid dates
      setCandidateValue(undefined);
      setComponentErrorMessage(`${candidateValue} is not available. Please select another date.`);
    } else {
      //Valid date
      setDate(candidateMoment.toDate());
      setComponentErrorMessage(undefined);
    }
  }, [value, candidateValue, setDate, limit]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      (e.target as HTMLElement).blur();
    }
  };

  const handleBlur = useCallback(() => {
    setIsDirty(true);
    tryUpdateValue();
  }, [setIsDirty, tryUpdateValue]);

  //Show error when validation fails or there are component level error
  const showError = ((forceErrorShown || isDirty) && !valid && valid !== undefined) || componentErrorMessage;

  return (
    <Input
      className={classNames(
        'cursor-pointer w-full flex items-center space-x-2 rounded-lg bg-white border ring-0 group-focus-within:ring-1 ring-primary-60 group-focus-within:border-primary-60 py-3 pl-4 pr-3',
        {
          'border-error': showError,
          'border-gray-30 hover:border-gray-80': !showError,
        },
      )}
      label={label}
      helperText={isCalendarOpen ? undefined : helperText}
      errorMessage={isCalendarOpen ? undefined : errorMessage || componentErrorMessage}
      valid={valid !== false && !componentErrorMessage}
      showValidityIcon={false}
      forceErrorShown={forceErrorShown || Boolean(componentErrorMessage)}
      value={candidateValue}
      onInputChange={(value: string) => {
        setCandidateValue(formatDate(value, candidateValue));
      }}
      onFocus={() => {
        //clear component error message upon interaction
        setComponentErrorMessage(undefined);
        openCalendar(true);
      }}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      placeholder={placeholderText}
      unpaddedSuffix={
        <button
          tabIndex={-1}
          onClick={() => {
            openCalendar(!isCalendarOpen);
          }}
          className="focus:outline-none"
          type="button"
        >
          <CalendarIcon
            onClick={() => {
              openCalendar(!isCalendarOpen);
            }}
            className="flex-shrink-0 w-5 h-5 text-gray-50 group-focus-within:text-primary-60"
          />
        </button>
      }
      maxLength={10}
    />
  );
};
