import { ITEM, LIST } from '../keys';

export const all = ['project'] as const;
export const lists = [...all, LIST] as const;
export const list = <T extends Record<string, unknown>>(opts: T) => [...lists, opts] as const;
export const items = [...all, ITEM] as const;
export const item = (id: string) => [...items, id] as const;

export const meta = (id: string) => [...items, id, 'meta'] as const;
export const remove = (id: string) => [...items, id, 'remove'] as const;
export const members = (id: string) => [...items, id, 'member', LIST] as const;
export const submissions = (id: string) => [...items, id, 'submission', LIST] as const;
