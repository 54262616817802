/* istanbul ignore file */

import axios from 'axios';
import { axiosInstance } from './axiosInstance';
import { IRoad, IMukimTownSubdivision, CODE_TABLE } from 'utils/constants';
import { Dispatch } from 'redux';
import { setCodeTable } from 'store/codeTables/actions';
import qs from 'qs';
import { isEmpty } from 'lodash';

interface IAddressResponse {
  found: number;
  pageNum: number;
  totalNumPages: number;
  results: {
    ADDRESS: string;
    BLK_NO: string;
    BUILDING: string;
    POSTAL: string;
    ROAD_NAME: string;
    SERACHVAL: string;
  }[];
}

interface IFetchAddressParams {
  postalCode: string;
}

export interface IFetchAddressData {
  unformatted: string;
  buildingName: string;
  roadName: string;
  blockNo: string;
  postalCode: string;
}

export const fetchAddress = async ({ postalCode }: IFetchAddressParams): Promise<IFetchAddressData[]> => {
  return axios
    .get<IAddressResponse>(
      `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=N&getAddrDetails=Y&pageNum=1`,
    )
    .then(async ({ data }) => {
      let finalResults: IAddressResponse['results'] = data.results;
      if (data.totalNumPages > 1) {
        finalResults = [
          ...finalResults,
          ...((await fetchNextPageAddresses(data.totalNumPages, postalCode)) as IAddressResponse['results']),
        ];
      }
      const results = finalResults
        .map((result) => {
          const { ADDRESS, BLK_NO, BUILDING, ROAD_NAME, POSTAL } = result;

          return {
            unformatted: ADDRESS,
            buildingName: BUILDING,
            roadName: ROAD_NAME,
            blockNo: BLK_NO,
            postalCode: POSTAL,
          };
        })
        .filter((result) => result.postalCode === postalCode);

      if (results.length === 0) {
        throw new Error('No address found');
      }

      return results;
    });
};

const fetchNextPageAddresses = async (totalNumPages: number, postalCode: string) => {
  try {
    const promiseArray: Promise<IAddressResponse>[] = [];
    const resultArray: IAddressResponse['results'] = [];
    // exclude page 1
    for (let i = 2; i <= totalNumPages; i++) {
      promiseArray.push(
        axios
          .get<IAddressResponse>(
            `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=N&getAddrDetails=Y&pageNum=${i}`,
          )
          .then()
          .catch((err) => err),
      );
    }
    const resolvedPromises: { data: IAddressResponse }[] = await Promise.all(promiseArray).then();
    for (let i = 0; i < resolvedPromises.length; i++) {
      resolvedPromises[i].data.results.forEach((result) => {
        resultArray.push(result);
      });
    }
    return resultArray;
  } catch (err) {
    console.log(`Error fetching next page APIs: ${err}`);
  }
};

interface IFetchRoadsResponse {
  roads: IRoad[];
}

export const fetchRoads = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance.get<IFetchRoadsResponse>('/road').then(({ data: { roads } }) => {
    dispatch(setCodeTable(CODE_TABLE.ROADS, roads));
  });
};

interface IFetchRoadsBySearchTerm {
  searchTerm: string;
  limit?: number;
}

export const fetchRoadsBySearchTerm = async ({
  searchTerm = '',
  limit = 5,
}: IFetchRoadsBySearchTerm): Promise<IRoad[]> => {
  const searchParams = qs.stringify({
    searchTerm,
    limit,
  });
  return axiosInstance.get<IFetchRoadsResponse>(`/road?${searchParams}`).then(({ data: { roads } }) => roads);
};

export const fetchRoadsByCode = async (codes: string[]): Promise<IRoad[]> => {
  if (isEmpty(codes)) return [];

  const query = qs.stringify({
    codes,
  });
  return axiosInstance.get<IFetchRoadsResponse>(`/road?${query}`).then(({ data: { roads } }) => roads);
};

interface IFetchMktsResponse {
  mukimTownSubdivisions: IMukimTownSubdivision[];
}

export const fetchMukimTownSubdivisions = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IFetchMktsResponse>('/mukim-town-subdivision')
    .then(({ data: { mukimTownSubdivisions } }) => {
      dispatch(setCodeTable(CODE_TABLE.MKTS, mukimTownSubdivisions));
    });
};
