import classNames from 'classnames';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { CardList } from '../Card/CardList';
import { DescriptionList } from '../DescriptionList/DescriptionList';
import { TextLink } from '../TextLink/TextLink';

type EmphasisLevel = 'high' | 'medium' | 'low';

// TODO: 327 Once these changed are merged, can only pass array without any condition
interface ITimelineCardContent {
  title: string;
  notification?: React.ReactNode;
  items: {
    label: string;
    data: React.ReactNode;
  }[];
  secondaryLink?: React.ReactNode;
  primaryAction?: React.ReactNode;
}

export interface IEvent {
  emphasisLevel?: EmphasisLevel;
  /** Shown when first item of timeline */
  primaryIcon: React.ReactNode;
  /** Shown when not first item of timeline */
  secondaryIcon: React.ReactNode;
  title: string;
  description?: string;
  forceShowDescription?: boolean;
  date: string;
  showLink?: boolean;
  link?: {
    label: React.ReactNode;
    alternateLabel?: React.ReactNode;
    onClick: () => void;
  };
  descriptionListContent?: {
    heading: string;
    items: {
      label: string;
      data: React.ReactNode;
    }[];
  };
  cardListContent?: ITimelineCardContent[] | ITimelineCardContent;
  collapsedContent?: React.ReactNode;
  additionalContent?: React.ReactNode;
}

export interface ITimeline {
  events: IEvent[];
  expandedView?: boolean;
}

export const Timeline: React.FC<ITimeline> = ({ events, expandedView = false }) => {
  return (
    <div className={classNames('flex flex-col space-y-0.5')}>
      {events.map(
        (
          {
            emphasisLevel = 'low',
            primaryIcon,
            secondaryIcon,
            title,
            date,
            description,
            forceShowDescription = false,
            showLink = false,
            link,
            descriptionListContent,
            cardListContent,
            collapsedContent,
            additionalContent,
          },
          i,
        ) => (
          <div className="flex space-x-3" key={i}>
            <div className="flex flex-col space-y-0.5 items-center">
              <div className="flex-shrink-0 h-6 w-6">{i === 0 ? primaryIcon : secondaryIcon}</div>

              {i !== events.length - 1 && <div className="w-px h-full bg-gray-30/60" />}
            </div>
            <div className="flex flex-col space-y-6 pb-8 grow">
              <div className="flex flex-col space-y-3">
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col space-y-1">
                    <div
                      className={classNames('text-gray-90', {
                        'heading-6-semibold': emphasisLevel === 'high' || i === 0 || expandedView,
                        'sub-heading': emphasisLevel === 'medium' && i !== 0 && !expandedView,
                        'label-large': emphasisLevel === 'low' && i !== 0 && !expandedView,
                      })}
                    >
                      {title}
                    </div>
                    <div className="label-regular text-gray-60">{date}</div>
                  </div>

                  {(!expandedView || forceShowDescription) && i === 0 && description && (
                    <div className="body-small text-gray-70">{description}</div>
                  )}
                </div>

                {(expandedView || i === 0) && link && (
                  <div className="w-max">
                    <TextLink onClick={link.onClick}>{link.label}</TextLink>
                  </div>
                )}
                {!expandedView && i !== 0 && showLink && link && (
                  <div className="w-max">
                    <TextLink onClick={link.onClick}>{link.alternateLabel}</TextLink>
                  </div>
                )}

                {!expandedView && i !== 0 && collapsedContent}
              </div>

              <ErrorBoundary>
                {(expandedView || i === 0) &&
                  cardListContent &&
                  (Array.isArray(cardListContent) ? cardListContent : [cardListContent]).map(
                    ({ title, notification, items, primaryAction, secondaryLink }, index) => (
                      <CardList
                        key={index}
                        title={title}
                        notification={notification}
                        dataItems={items}
                        primaryAction={!expandedView ? primaryAction : null}
                        secondaryLink={!expandedView ? secondaryLink : null}
                      />
                    ),
                  )}
                {(expandedView || i === 0) && descriptionListContent && (
                  <DescriptionList header={descriptionListContent.heading} items={descriptionListContent.items} />
                )}
                {(expandedView || i === 0) && additionalContent}
              </ErrorBoundary>
            </div>
          </div>
        ),
      )}
    </div>
  );
};
