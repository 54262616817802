import React from 'react';
import { Tag } from '../Tag/Tag';

type IVariant = 'default' | 'stacked' | 'single';
type IItemVariant = 'default' | 'bulleted';

export interface IDescriptionListItem {
  label?: string;
  itemVariant?: IItemVariant;
  data: React.ReactNode;
  updated?: boolean;
  order?: number;
}

export interface IDescriptionList {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  description?: React.ReactNode;
  items: IDescriptionListItem[];
  actionNode?: React.ReactNode;
  variant?: IVariant;
  updated?: boolean;
  dataCy?: string;
}

export const DescriptionList: React.FC<IDescriptionList> = ({
  header,
  subheader,
  description,
  items,
  actionNode,
  variant = 'default',
  updated,
  dataCy = 'description-list-table',
}) => (
  <div data-cy={dataCy} className="w-full rounded-lg bg-white shadow-sm divide-gray-20/60">
    {(header || subheader || actionNode || description) && (
      <>
        <div className="flex flex-col py-4 px-5">
          <div className="flex justify-between items-center">
            {header && <div className="text-gray-90 heading-6-semibold">{header}</div>}
            {subheader && <div className="text-gray-90 sub-heading">{subheader}</div>}
            {actionNode && <div className="ml-3">{actionNode}</div>}
          </div>
          {description && <div className="text-gray-70 body-small">{description}</div>}
          {updated && (
            <div className="mt-2">
              <Tag text="Updated" />
            </div>
          )}
        </div>
        <hr />
      </>
    )}

    {variant === 'default' && (
      <div className="grid grid-cols-8 divide-gray-20/60 gap-x-6">
        {items.map(({ label, data, itemVariant, updated }, i) => (
          <React.Fragment key={i}>
            <div className="label-large col-span-3 text-gray-60 py-4 pl-5">{label}</div>
            <div className="body-small col-span-5 text-gray-90 py-4 pr-5 break-words">
              {itemVariant === 'bulleted' ? (
                <ul className="list-disc list-inside">
                  {React.Children.map(data, (dataChild, index) => {
                    return <li key={index}>{dataChild}</li>;
                  })}
                </ul>
              ) : (
                data
              )}
              {updated && (
                <div className="mt-2.5">
                  <Tag text="Updated" />
                </div>
              )}
            </div>
            {i !== items.length - 1 && <hr className="col-span-8" />}
          </React.Fragment>
        ))}
      </div>
    )}

    {variant === 'stacked' && (
      <div className="flex flex-col divide-gray-20/60">
        {items.map(({ label, data, itemVariant, updated }, i) => (
          <React.Fragment key={i}>
            <div className="flex flex-col space-y-1 py-4 px-5">
              <div className="over-line text-gray-60">{label}</div>
              <div className="body-small text-gray-90 break-words">
                {itemVariant === 'bulleted' ? (
                  <ul className="list-disc list-inside">
                    {React.Children.map(data, (dataChild, index) => {
                      return <li key={index}>{dataChild}</li>;
                    })}
                  </ul>
                ) : (
                  data
                )}
                {updated && (
                  <div className="mt-2.5">
                    <Tag text="Updated" />
                  </div>
                )}
              </div>
            </div>
            {i !== items.length - 1 && <hr className="col-span-2" />}
          </React.Fragment>
        ))}
      </div>
    )}

    {variant === 'single' && (
      <div className="divide-gray-20/60 gap-x-6">
        {items.map(({ label }, i) => (
          <React.Fragment key={i}>
            <div className="label-large text-gray-60 py-4 pl-5">{label}</div>
            {i !== items.length - 1 && <hr className="col-span-8" />}
          </React.Fragment>
        ))}
      </div>
    )}
  </div>
);
