import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Header } from './Header';
import { DatePickerInput } from './DatePickerInput';
import { Calendar } from './Calendar';
import { IDateRange } from './types';
import { DatePickerGroup } from './DatePickerGroup';
import moment from 'moment';
import useTopBottomReposition from '../hooks/useTopBottomReposition';
import classNames from 'classnames';
import { formatDateInNumeric } from 'utils/date';

export interface IDatePicker {
  date: Date | null;
  setDate: (date: Date | null) => void;
  limit?: IDateRange;
  validDates?: Date[];
  placeholderText?: string;
  label?: string;
  helperText?: string;
  errorMessage?: string;
  valid?: boolean;
  forceErrorShown?: boolean;
}

export const DatePicker: React.FC<IDatePicker> = ({
  date,
  setDate,
  limit = { startDate: null, endDate: null },
  validDates,
  placeholderText,
  label,
  helperText,
  errorMessage,
  valid,
  forceErrorShown,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const now = useRef(new Date());
  const dateOrDefault = date || now.current;

  const [renderedDate, setRenderedDate] = useState(dateOrDefault);

  useEffect(() => {
    setRenderedDate(dateOrDefault);
  }, [isOpen, dateOrDefault]);

  const decreaseMonth = useCallback(
    () => setRenderedDate(moment(renderedDate).subtract(1, 'M').toDate()),
    [renderedDate, setRenderedDate],
  );

  const increaseMonth = useCallback(
    () => setRenderedDate(moment(renderedDate).add(1, 'M').toDate()),
    [renderedDate, setRenderedDate],
  );

  const [calendarRef, positionStyles] = useTopBottomReposition<HTMLDivElement>({ isOpen });

  const handleSetDate = (date: Date | null) => {
    setDate(date);
  };

  return (
    <div className="w-full relative group" ref={calendarRef}>
      <DatePickerGroup closeDatePicker={() => setIsOpen(false)}>
        <DatePickerInput
          value={date ? formatDateInNumeric(date) : ''}
          placeholderText={placeholderText}
          openCalendar={setIsOpen}
          isCalendarOpen={isOpen}
          setDate={handleSetDate}
          limit={limit}
          validDates={validDates}
          label={label}
          helperText={helperText}
          errorMessage={errorMessage}
          valid={valid}
          forceErrorShown={forceErrorShown}
        />

        {isOpen && (
          <div className={classNames('absolute left-0 w-full z-50', positionStyles)}>
            <div className="flex flex-col items-center bg-white rounded-lg border border-primary-60 ring-1 ring-primary-60 shadow-md mt-2 min-w-[306px] md:min-w-[322px]">
              <Header date={renderedDate} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
              <Calendar
                month={renderedDate.getMonth()}
                year={renderedDate.getFullYear()}
                selection={{ startDate: date, endDate: date }}
                limit={limit}
                validDates={validDates}
                setDate={handleSetDate}
              />
            </div>
          </div>
        )}
      </DatePickerGroup>
    </div>
  );
};
